<template>
    <div>
        <loading v-show="pageLoading"/>
        <div v-show="!pageLoading">
            <div v-show="formProcess=='emailSend'">
                <ValidationObserver ref="formEmailSend">
                    <ValidationProvider name="email" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('email')">
                            <b-form-input v-model="email" type="email"
                                          :state="errors[0] ? false :null"

                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="d-flex justify-content-center">
                        <b-button variant="primary"
                                  @click="emailSend"
                                  :disabled="formLoading">
                            {{ $t('update') }}
                        </b-button>
                    </div>
                </ValidationObserver>
            </div>
            <div v-show="formProcess=='emailVerify'">
                <b-form-group :label="$t('email')">
                    <div class="label-as-input">{{ email }}</div>
                </b-form-group>
                <div class="mb-3">
                    <ValidationObserver ref="formEmailVerify">
                        <ValidationProvider name="email_code" rules="required" v-slot="{valid, errors}">
                            <sms-input v-model="emailCode"
                                       :timerFrom="emailTimer"
                                       @timerExpired="timerExpired"
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <b-row>
                    <b-col>
                        <b-button type="button"
                                  variant="primary"
                                  size="lg"
                                  block
                                  @click="emailVerify"
                                  :disabled="emailTimer==0"
                        >
                            {{ $t('verify') }}
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-button type="submit"
                                  variant="outline-secondary"
                                  size="lg"
                                  block
                                  @click="emailSend()"
                                  :disabled="emailTimer>0"
                        >
                            {{ $t('send_again') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    // Components
    import Loading from '@/components/elements/Loading.vue';
    import SmsInput from '@/components/elements/SmsInput.vue';

    // Services
    import PersonService from '@/services/PersonService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            Loading,
            SmsInput,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            emailAddress: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                // Page
                pageLoading: false,

                // Form
                formLoading: false,
                formProcess: null,

                // Form
                email: null,
                emailTimer: 0,
                emailCode: ''
            }
        },
        async created() {
            this.email = this.emailAddress;
            this.formProcess = 'emailSend';
        },
        mounted() {
            this.emailTimer = 10
        },
        methods: {
            async emailSend() {
                const isValid = await this.$refs.formEmailSend.validate();
                if (isValid) {
                    let formData = {
                        email: this.email
                    }
                    PersonService.sendEmailCode(formData)
                                 .then(response => {
                                     let data = response.data.data;
                                     this.emailTimer = data.expires_in
                                 })
                                 .then(() => {
                                     this.formProcess = 'emailVerify'
                                 })
                                 .catch(e => {
                                     this.showErrors(e, this.$refs.formEmailSend);
                                 })

                }
            },
            timerExpired(event) {
                this.emailTimer = 0;
            },

            async emailVerify() {
                const isValid = await this.$refs.formEmailVerify.validate();
                if (isValid) {
                    let formData = {
                        email_code: this.emailCode
                    }
                    this.formLoading = true;
                    PersonService.verifyEmailCode(formData)
                                 .then(response => {
                                     let message = response.data.message
                                     this.$toast.success(this.$t('api.' + message));
                                     this.$emit('emailUpdated', this.email)
                                 })
                                 .catch(e => {
                                     this.showErrors(e, this.$refs.addressForm)
                                 })
                                 .finally(() => {
                                     this.formLoading = false;
                                 })
                }
            }
        }
    }
</script>
