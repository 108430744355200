<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('update_contact_info')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('update_contact_info')" :is-filter="false">
                </HeaderMobile>
            </template>
            <CommonModal id="mobileTelModal" size="md" :close-btn="false" :no-close-on-esc="true" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t("update_mobile_tel") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center" v-if="user && user.person">
                        <div class="col-12">
                            <update-mobile-tel
                                :mobile_telephone="user.person.mobile_tel ? user.person.mobile_tel:''"
                                @mobileNumberUpdated="mobileNumberUpdated()"
                            ></update-mobile-tel>
                        </div>
                    </div>
                </template>
            </CommonModal>

            <CommonModal id="emailModal" size="md" :close-btn="false" :no-close-on-esc="true" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t("update_email") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center" v-if="user && user.person">
                        <div class="col-12">
                            <update-email
                                :email-address="user.person.personal_email ? user.person.personal_email:''"
                                @emailUpdated="emailUpdated()"
                            ></update-email>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import CommonModal from "@/components/elements/CommonModal";
import UpdateMobileTel from "./UpdateMobileTel";
import UpdateEmail from "./UpdateEmail";

export default {
    components: {
        UpdateEmail,
        UpdateMobileTel,
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("update_contact_info"),
        };
    },
    data() {
        return {
            user:null
        };
    },
    methods: {
        mobileNumberUpdated(){
            this.$bvModal.hide('mobileTelModal')
            this.$bvModal.show('emailModal')
            this.$store.dispatch("auth/initUser")
        },
        emailUpdated(){
            this.$store.dispatch("auth/initUser")
            this.$bvModal.hide('emailModal')
            this.$router.push('/')
        }
    },
    mounted() {
        this.user = this.$store.getters['auth/getUser'];
        if(this.user && this.user.collect_mobile_tel){
            this.$bvModal.show('mobileTelModal')
        }
        else if(this.user && this.user.collect_personal_email){
            this.$bvModal.show('emailModal')
        }
        else {
            this.$bvModal.show('mobileTelModal')
        }
    }
};
</script>

